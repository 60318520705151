import React from "react"
import Layout from "../components/layout"

const B = () => (
  <Layout>
    <p>B</p>
  </Layout>
)

export default B
